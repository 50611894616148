<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getData"
              @onShowDetails="onShowDetails"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';

export default {
  components: {
    VueTable,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'notification_id',
            mask: 'ID',
            sortable: true,
          },
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'sender.name',
            mask: 'sender',
          },
          {
            title: 'created_at',
            mask: 'created at',
            sortable: true,
            dateTimeFormat: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-info',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'visibility',
          },
        ],
        values: {},
      },
      loading: false,
    };
  },
  mounted() {
    this.initTable();
  },
  methods: {
    onLoading(val) {
      this.loading = val;
    },
    initTable() {
      this.$refs.vtable.init();
    },
    getData(params) {
      this.loading = true;
      this.request('app/notifications', 'get', params, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    onShowDetails(item) {
      let html = `<div class="text-left">
          <h4>Title</h4>
          <p>${item.title}</p>

          <h4>Body</h4>
          <p>${item.body}</p>`;

      if (item.to_all) {
        html += '<h4>Addressed to ALL STUDENTS</h4></div>';
      } else {
        html += `<h4>Addressed Students</h4>
          <table class="table no-borders text-left">
            <tr>
              <th>Student Number</th>
              <th>Name</th>
            </tr>
            ${item.students.map((x) => `<tr>
                <td>${x.student_number}</td>
                <td>${x.full_name}</td>
              </tr>`).join('')}
          </table></div>`;
      }

      this.fireModal({
        title: 'Notification Details',
        html,
        customClass: 'modal-md',
      });
    },
  },
};
</script>

<style scoped>
</style>
